<!-- Authentication section -->
<div class="auth-container" *ngIf="!authed" [@dummyAnimation]>
  <h1 [@titlePopInOut]="'popIn'"><img src="assets/title.svg" /></h1>
  <div class="digits" [@titlePopInOut]>
    <span *ngFor="let i of [].constructor(digits.length)">•</span>
  </div>
  <div class="buttons" @buttonPopInOut>
    <div class="button-row">
      <button
        *ngFor="let number of [1, 2, 3]"
        (click)="appendDigit(number)"
        class="button"
      >
        {{ number }}
      </button>
    </div>
    <div class="button-row">
      <button
        *ngFor="let number of [4, 5, 6]"
        (click)="appendDigit(number)"
        class="button"
      >
        {{ number }}
      </button>
    </div>
    <div class="button-row">
      <button
        *ngFor="let number of [7, 8, 9]"
        (click)="appendDigit(number)"
        class="button"
      >
        {{ number }}
      </button>
    </div>
    <div class="button-row">
      <button (click)="resetDigits()" class="button">C</button>
      <button (click)="appendDigit('0')" class="button">0</button>
      <button (click)="loadPosts()" class="button">✓</button>
    </div>
  </div>
  <!-- <button (click)="loadPosts()">Auth</button> -->
  <!-- <button (click)="resetDigits()">Reset</button> -->
</div>

<!-- View after auth -->
<div class="main-view" *ngIf="authed">
  <div class="composer" [class.visible]="this.composerVisible">
    <form [formGroup]="form" (ngSubmit)="post()">
      <!-- <textarea [(ngModel)]="this.composerContent"></textarea> -->
      <div
        #messageInput
        id="messageInput"
        contenteditable="true"
        formControlName="messageContent"
      ></div>
      <label class="image-uploader">
        <input type="file" (change)="onFileSelect($event)" accept="image/*" />
        <span *ngIf="!attachedImage">贴个图</span>
        <span *ngIf="attachedImage" class="ready">贴上了！</span>
      </label>
      <button class="confirm" type="submit">
        <span *ngIf="fileUploadProgress === ''"> 发布 </span>
        <span *ngIf="fileUploadProgress !== ''">
          {{ fileUploadProgress === "100" ? "稍等" : fileUploadProgress + "%" }}
        </span>
      </button>
      <button (click)="hideComposer()" class="cancel" type="reset">算了</button>
    </form>
  </div>
  <div
    class="composer-toggle-container"
    *ngIf="authed && !composerVisible"
    @buttonPopInOut
  >
    <button class="showComposer button" (click)="showComposer()">
      <img src="assets/add-24px.svg" />
    </button>
  </div>
  <div class="posts">
    <app-post *ngFor="let post of posts" [post]="post" [authed]="authed">
    </app-post>
  </div>
  <div class="page-nav" @navPopInOut>
    <button (click)="prevPage()" [disabled]="this.currentPage <= 1">
      <img src="assets/arrow_back-24px.svg" />
    </button>
    <span>{{ this.currentPage }} of {{ this.totalPages }}</span>
    <button
      (click)="nextPage()"
      [disabled]="this.currentPage >= this.totalPages"
    >
      <img src="assets/arrow_forward-24px.svg" />
    </button>
  </div>
</div>
<div class="auth-bg" *ngIf="!authed" [@bgSlideOut]="'slideOut'"></div>
<router-outlet></router-outlet>
